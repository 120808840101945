import React from 'react';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { applyMiddleware, createStore, compose } from 'redux';
import { routerMiddleware, ConnectedRouter } from 'connected-react-router';

import rootReducer from './redux/Reducer';
import { setCurrentUser, setToken } from './redux/actions/Login';
import { isEmpty } from './utils/Utils';

export const history = createBrowserHistory();
const Root = ({ children, initialState = {} }) => {
  const store = createStore(
    rootReducer(history),
    initialState,
    compose(
      applyMiddleware(thunk, routerMiddleware(history))
    )
  );

  if (!isEmpty(localStorage.getItem('workflowmanager-token'))) {
    store.dispatch(setToken(localStorage.getItem('workflowmanager-token')));
  }
  if (!isEmpty(localStorage.getItem('workflowmanager-user'))) {
    const user = JSON.parse(localStorage.getItem('workflowmanager-user'));
    store.dispatch(setCurrentUser(user, ''));
  }

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>{children}</ConnectedRouter>
    </Provider>
  );
};

export default Root;
