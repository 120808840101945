import { useState, useEffect } from 'react';
import { FlagButton, PlayButton, StopButton, CheckButton, ConfirmButton } from '../shared/ReactToolbox';

import { use } from '../../redux/Factory';

const TaskActions = ({ task }) => {
  const {
    startTask,
    finishTask,
    resignTask,
    unSelectTask,
    flagTask,
    unFlagTask,
  } = use.tasks();
  const [whichIsLoading, setWhichIsLoading] = useState(null);
  return (
    task.assigned_to
      ? <>
          <FlagButton
            variant={task.is_flagged ? 'danger' : 'light'}
            onClick={() => {
              setWhichIsLoading(task);
              if (task.is_flagged) {
                unFlagTask(task);
              } else {
                flagTask(task);
              }
            }}
            loading={(flagTask.isLoading || unFlagTask.isLoading) && whichIsLoading && whichIsLoading.id === task.id}
          />
          <ConfirmButton
            variant="outline-danger"
            modalTitle='Deze taak afronden'
            modalBody='Weet je zeker dat je deze taak wilt afronden?'
            confirmText='Afronden'
            onConfirm={() => {
              setWhichIsLoading(task);
              finishTask(
                task,
                { callback: unSelectTask }
              );
            }}
            buttonComponent={CheckButton}
            loading={finishTask.isLoading && whichIsLoading && whichIsLoading.id === task.id}
          >
            Taak afronden
          </ConfirmButton>
          <StopButton
            variant="outline-dark"
            onClick={() => {
              setWhichIsLoading(task);
              resignTask(task);
            }}
            loading={resignTask.isLoading && whichIsLoading && whichIsLoading.id === task.id}
          >
            Taak vrijgeven
          </StopButton>
        </>
      : <>
          <PlayButton
            variant={task.started_at
              ? 'outline-dark'
              : 'outline-grey'
            }
            className="float-end"
            onClick={() => {
              setWhichIsLoading(task);
              startTask(task);
            }}
            loading={startTask.isLoading && whichIsLoading && whichIsLoading.id === task.id}
          >
            {task.started_at
              ? 'Taak oppakken'
              : 'Taak starten'
            }
          </PlayButton>
      </>
  );
};
export default TaskActions;
