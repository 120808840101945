import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Container, Button, Card, Alert } from 'react-bootstrap';

import { use } from '../../redux/Factory';
import TaskActions from './TaskActions';
import {
  SmallSpinner,
  DataTable,
  FormTextArea,
  useDebouncedEffect,
  usePrevious,
} from '../shared/ReactToolbox';

export const getTaskName = task => <>{task.task_template.name} {task.name && `(${task.name})`}</>;

const Task = ({ task }) => {
  const user = useSelector(({ auth }) => auth.user);
  const { updateLog } = use.tasks();
  const taskStarted = !task.finished_at && task.assigned_to === user.id;

  const currentLog = taskStarted && task.logs.find(
    ({ finished_at, user: otherUser }) => finished_at === null && otherUser.id === user.id
  );
  const [notes, setNotes] = useState(taskStarted && currentLog && currentLog.notes);
  const [pristine, setPristine] = useState(true);
  useDebouncedEffect(
    () => {
      if (taskStarted && currentLog.notes !== notes) {
        updateLog({ ...currentLog, notes }, { args: { task } });
      }
    },
    [notes],
    5000
  );
  const previousTask = usePrevious(task)
  useEffect(() => {
    if (pristine && notes !== currentLog.notes) {
      setPristine(false);
    }
    if (previousTask && task.id !== previousTask.id) {
      // Reset notes edit field when clicking on a different task
      setNotes(taskStarted && currentLog && currentLog.notes);
    }
  }, [notes, task, previousTask])

  return (
    <Container className='container-item'>
      {task.is_flagged &&
        <Alert variant="danger" className='vertical-space'>
          Deze taak direct uitvoeren
        </Alert>
      }
      <div className="float-end" >
        <TaskActions task={task} />
      </div>
      <h4>{getTaskName(task)}</h4>
      {task.contact && `${task.contact.last_name} - `}{task.project.name}

      {task.notes &&
        <Card className='vertical-space'>
          <Card.Body>
            {/* <Card.Title>Opdracht</Card.Title> */}
            <Card.Text>
              {task.notes}
            </Card.Text>
          </Card.Body>
        </Card>
      }
      {task.custom_fields.length > 0 &&
        <Alert variant="info" className='vertical-space'>
          <Alert.Heading>Informatie uit de offerte</Alert.Heading>
          {task.custom_fields.map((cf, key) =>
            <div key={cf.id}>
              <hr/>
              <p>
                <strong>{cf.definition.label}</strong>:&nbsp;
                {
                  cf.value === null
                  ? <italic>Niet ingevoerd</italic>
                  : cf.definition.type === 'boolean'
                  ? (cf.value ? 'Ja' : 'Nee')
                  : cf.value
                }
              </p>
            </div>
          )}
        </Alert>
      }
      {taskStarted &&
        <>
          <div className='vertical-space'>
            <div className="float-end">
              {updateLog.isLoading && <SmallSpinner />}
              <Button
                className='vertical-space'
                variant="primary"
                size=""
                disabled={currentLog.notes === notes}
                onClick={() => updateLog({ ...currentLog, notes }, { args: { task } })}
                title=""
              >
                Opslaan
              </Button>
            </div>

            {!pristine && notes !== currentLog.notes && <i>&nbsp;(niet opgeslagen)</i>}
          </div>

          <FormTextArea
            value={notes}
            onChange={setNotes}
          />
        </>
      }
      {task.logs.length > 0 &&
        <DataTable
          className='vertical-space'
          columns={[
            // {
            //   className: 'd-table-cell d-md-none',
            //   name: 'Datum',
            //   selector: ({ started_at, finished_at }) =>
            //     `${
            //       moment(started_at).format('DD-MM-YYYY HH:mm')
            //     }${
            //       finished_at ? ` / ${moment(finished_at).format('DD-MM-YYYY HH:mm')}` : ``
            //     }`,
            // },
            {
              className: 'd-none d-md-table-cell',
              name: 'Startdatum',
              selector: ({ started_at }) => moment(started_at).format('DD-MM-YYYY HH:mm'),
            },
            {
              className: 'd-none d-md-table-cell',
              name: 'Einddatum',
              selector: ({ finished_at }) =>
                finished_at ? moment(finished_at).format('DD-MM-YYYY HH:mm') : <i>Nog niet afgesloten</i>,
            },
            {
              name: 'Gebruiker',
              selector: ({ user: { name, username } }) => name || <italic>{username}</italic>,
            },
            {
              name: 'Notities',
              selector: ({ notes }) => notes,
            },
          ]}
          data={task.logs.filter(({ finished_at }) => !!finished_at)}
          showHeader={false}
          orderByDefault='started_at'
        />
      }
    </Container>
  )
};
export default Task;
