
import axios from '../utils/Axios';
import { toastOnError } from '../utils/Utils';
import reduxCrudFactory from '../utils/ReduxCrudFactory';

import { setCurrentUser } from './actions/Login';

const factory = reduxCrudFactory({
  axios,
  onError: toastOnError,
  config: {
    tasks: {
      actions: {
        select: 'single',
        get: true,
        getList: true,
        create: true,
        update: true,
        delete: true,
      },
      route: '/api/webapp/tasks/',
      includeActions: {
        ...Object.entries({
          startTask: 'start',
          resignTask : 'resign',
          finishTask: 'finish',
          flagTask: 'flag',
          unFlagTask: 'unflag',
        }).reduce((o, [action, route]) => ({
          ...o,
          [action]: {
            route: task => `/api/webapp/tasks/${task.id}/${route}/`,
            method: 'post',
            onResponse: (tasksList, { data: task, setTasksList, selectTask, unSelectTask, ...r }) => {
              setTasksList(tasksList);
              if (action === 'startTask') {
                selectTask(tasksList.find(({ id }) => id === task.id));
              } else if (action === 'resignTask' || action === 'finishTask') {
                unSelectTask();
              }
            },
          },
        }), {}),
        updateLog: {
          route: ({}, { args }) => `/api/webapp/tasks/${args.task.id}/update-log/`,
          method: 'post',
          onResponse: (tasksList, { setTask }) => setTask(tasksList),
        },
      },
    },
    users: {
      route: '/api/admin/users/?is_staff=false',
      actions: {
        getList: true,
      },
      includeActions: {
        setWebappUser: {
          route: '/api/admin/users/set-webapp-user/',
          method: 'post',
          onResponse: (userData, { dispatch, clearTasksList, getTasksList }) => {
            dispatch(setCurrentUser(userData));
            clearTasksList();
            getTasksList();
          }
        }
      }
    },
  },
});

export default factory;
export const use = factory.hooks;
